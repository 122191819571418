<template>
  <div class="template-container pc-wrap">
    <div class="wrap-box">
      <!-- banner -->
      <div class="banner-box">
        <img :src="newsData.bannerImage" alt="" />
      </div>
      <!-- 新闻详情 -->
      <div class="news-detail is-wrapper-box">
        <div class="callBack fontRegular" @click="goBack">
          <el-icon><DArrowLeft /></el-icon>
          &nbsp;返回
        </div>
        <div class="newsDetail">
          <h2 class="fontBold">{{ newDetail.title }}</h2>
          <div class="time fontRegular">{{ newDetail.createTime }} 发布</div>
          <p v-html="newDetail.content"></p>
          <!-- 小图标 -->
          <div class="one">
            <img :src="newsData.newsIcon1" alt="" />
          </div>
          <div class="tow">
            <img :src="newsData.newsIcon2" alt="" />
          </div>
          <div class="three">
            <img :src="newsData.newsIcon3" alt="" />
          </div>
        </div>
        <!-- 分页 -->
        <div class="pagination">
          <div
            class="prev fontRegular"
            @click="prevClick(adjacentNews.previousNews)"
            v-if="adjacentNews.previousNews"
          >
            上一篇：{{ adjacentNews.previousNews.title }}
          </div>
          <div class="btn">
            <el-button
              type="primary"
              @click="prevClick(adjacentNews.previousNews)"
              :disabled="!isPrev"
              v-if="adjacentNews.previousNews && adjacentNews.nextNews"
            >
              <el-icon><ArrowLeft /></el-icon>
            </el-button>

            <el-button
              type="primary"
              @click="nextClick(adjacentNews.nextNews)"
              :disabled="!isNext"
              v-if="adjacentNews.previousNews && adjacentNews.nextNews"
            >
              <el-icon><ArrowRight /></el-icon>
            </el-button>
          </div>

          <div
            class="next fontRegular"
            @click="nextClick(adjacentNews.nextNews)"
            v-if="adjacentNews.nextNews"
          >
            <div class="next">下一篇：{{ adjacentNews.nextNews.title }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  toRefs,
  getCurrentInstance,
  ref,
  reactive,
  watch,
  onMounted,
  onUnmounted,
} from "vue";
import datajs from "../data.js";

const { proxy } = getCurrentInstance();

const isPrev = ref(false);
const isNext = ref(false);

// 辅助参数
const {
  newDetail,
  newsData,
  init,
  nextClick,
  prevClick,
  goBack,
  adjacentNews,
} = datajs.apply(proxy);

watch(
  () => adjacentNews.value,
  (nVal) => {
    const { previousNews, nextNews = {} } = nVal;
    isPrev.value = !!previousNews;
    isNext.value = !!nextNews;
  }
);
onMounted(() => {
  init();
});

onUnmounted(() => {
  proxy.$renderTDKHtml();
});
</script>

<style lang="scss" scoped>
.template-container {
  &.pc-wrap {
    .is-wrapper-box {
      // width: calc(100vw - 620px);
      width: 100%;
      max-width: 1320px;
      margin: auto;
    }

    .wrap-box {
      background-color: rgba(244, 248, 253, 1);
      .banner-box {
        max-height: 5000px;
        height: 100%;
        width: 100%;
        object-fit: cover;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .news-detail {
        .callBack {
          margin: auto;
          font-size: 16px;
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 30px 0px;
          color: #666666;
        }
        .newsDetail {
          position: relative;
          scrollbar-width: none; /* firefox */
          -ms-overflow-style: none; /* IE 10+ */
          margin: 30px auto 50px;
          padding: 0 200px;
          &::-webkit-scrollbar {
            width: 0;
          }

          h2 {
            text-align: center;
            padding: 0 100px;
          }
          .time {
            text-align: center;
            margin: 8px 0 30px;
            color: #999999;
          }

          p {
            font-size: 14px;
            line-height: 24px;
          }

          .one {
            top: 630px;
            left: 35px;
            width: 70px;
            height: 70px;
          }

          .one,
          .tow,
          .three {
            position: absolute;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .tow {
            top: 30px;
            left: 140px;
            width: 80px;
            height: 80px;
          }

          .three {
            top: 350px;
            right: 47px;
            width: 153px;
            height: 153px;
            opacity: 0.5;
          }
        }

        .pagination {
          width: 100%;
          padding-bottom: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          .prev,
          .next {
            width: 430px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
          }
          .btn {
            .el-button {
              width: 40px;
              margin: 0 10px;
              background-color: #fff;
              border: 1px solid #dcdfe6;
              color: #000000;
            }
          }
        }
      }
    }
  }
}
</style>
