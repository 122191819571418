import { reactive, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
export default function () {
  const proxy = this;

  const dataStore = proxy.$usePiniaModule("dataStore");

  const router = useRouter();
  const route = useRoute();
  const newId = ref(route.query.id);

  const newsData = {
    // banner
    bannerImage: require("@/assets/images/news/news-banner-1.png"),
    newsIcon1: require("@/assets/images/news/news-detail-1.png"),
    newsIcon2: require("@/assets/images/news/news-detail-2.png"),
    newsIcon3: require("@/assets/images/news/news-detail-3.png"),
  };

  // 新闻详情
  const newDetail = ref({});

  // 获取新闻详情 -----------------

  // 相邻数据
  const adjacentNews = ref({});

  // 查询详情
  const getCurrDetail = async () => {
    try {
      const res = await proxy.$storeDispatch("fetchGetNews", {
        id: newId.value,
      });
      const result = res.result;
      newDetail.value = result;

      const { title, digest } = result;

      // 渲染 TDK
      proxy.$renderTDKHtml({
        title,
        content: digest,
      });
    } catch (error) {}
  };

  // 查询相邻数据
  const getAdjacentNews = async () => {
    try {
      const res = await proxy.$storeDispatch("fetchGetAdjacentNews", {
        id: newId.value,
      });
      const result = res.result;
      adjacentNews.value = result;
    } catch (error) {}
  };

  function prevClick(item) {
    // console.log("上一篇");
    const call = () => {
      newId.value = item.id;
      init();
    };

    // 记录鼠标操作
    dataStore.setGlobalClickRecord(function () {
      document
        .getElementsByClassName("pagination")[0]
        .getElementsByClassName("prev")[0]
        .click();
    });
    call();
  }
  function nextClick(item) {
    // console.log("下一篇");
    const call = () => {
      newId.value = item.id;
      init();
    };

    // 记录鼠标操作
    dataStore.setGlobalClickRecord(function () {
      document
        .getElementsByClassName("pagination")[0]
        .getElementsByClassName("next")[0]
        .click();
    });
    call();
  }

  // 返回
  const goBack = () => {
    // 记录鼠标操作
    dataStore.setGlobalClickRecord(function () {
      document
        .getElementsByClassName("news-detail")[0]
        .getElementsByClassName("callBack")[0]
        .click();
    });
    router.go(-1);
  };

  // 初始
  const init = async () => {
    if (newId.value) {
      await getCurrDetail();
      getAdjacentNews();
    }
  };

  return {
    newsData,
    newDetail,

    init,
    nextClick,
    prevClick,
    adjacentNews,
    goBack,
  };
}
