<template>
  <div class="template-container mobile-wrap">
    <div class="wrap-box">
      <!-- banner -->
      <div class="banner-box">
        <img :src="newsData.bannerImage" alt="" />
      </div>
      <!-- 新闻详情 -->
      <div class="news-detail is-wrapper-box">
        <div class="callBack" @click="goBack">
          <el-icon><DArrowLeft /></el-icon>
          &nbsp;返回
        </div>
        <div class="newsDetail">
          <h2>{{ newDetail.title }}</h2>
          <div class="time">{{ newDetail.createTime }} 发布</div>
          <p v-html="newDetail.content"></p>
        </div>
        <!-- 分页 -->
        <div class="pagination">
          <div
            class="prev"
            @click="prevClick(adjacentNews.previousNews)"
            v-if="adjacentNews.previousNews"
          >
            上一篇：{{ adjacentNews.previousNews.title }}
          </div>
          <div class="btn">
            <el-button
              type="primary"
              @click="prevClick(adjacentNews.previousNews)"
              :disabled="!isPrev"
              size="small"
              v-if="adjacentNews.previousNews && adjacentNews.nextNews"
            >
              <el-icon><ArrowLeft /></el-icon>
            </el-button>
            <el-button
              type="primary"
              @click="nextClick(adjacentNews.nextNews)"
              :disabled="!isNext"
              size="small"
              v-if="adjacentNews.previousNews && adjacentNews.nextNews"
            >
              <el-icon><ArrowRight /></el-icon>
            </el-button>
          </div>

          <div
            class="next"
            @click="nextClick(adjacentNews.nextNews)"
            v-if="adjacentNews.nextNews"
          >
            下一篇：{{ adjacentNews.nextNews.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  toRefs,
  getCurrentInstance,
  ref,
  reactive,
  watch,
  onMounted,
  onUnmounted,
} from "vue";
import datajs from "../data.js";

const { proxy } = getCurrentInstance();

const isPrev = ref(false);
const isNext = ref(false);

// 辅助参数
const {
  newDetail,
  newsData,
  init,
  nextClick,
  prevClick,
  goBack,
  adjacentNews,
} = datajs.apply(proxy);

// 获取新闻详情 -----------------

// 相邻数据
watch(
  () => adjacentNews.value,
  (nVal) => {
    const { previousNews, nextNews = {} } = nVal;
    isPrev.value = !!previousNews;
    isNext.value = !!nextNews;
  }
);

onMounted(() => {
  init();
});

onUnmounted(() => {
  proxy.$renderTDKHtml();
});
</script>

<style lang="scss" scoped>
.template-container {
  &.mobile-wrap {
    .is-wrapper-box {
    }

    .wrap-box {
      background-color: rgba(244, 248, 253, 1);
      .banner-box {
        width: 100%;
        object-fit: cover;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .news-detail {
        padding: 0 20px;
        .callBack {
          margin: auto;
          font-size: 14px;
          display: flex;
          align-items: center;
          padding: 10px 0px 18px;
          color: #666666;
        }
        .newsDetail {
          // height: 650px;
          overflow: hidden;
          overflow-y: auto;
          scrollbar-width: none; /* firefox */
          -ms-overflow-style: none; /* IE 10+ */

          &::-webkit-scrollbar {
            width: 0;
          }

          h2 {
            text-align: center;
            font-size: 20px;
          }
          .time {
            text-align: center;
            margin: 8px 0 15px;
            color: #999999;
            font-size: 14px;
          }

          p {
            font-size: 14px;
            line-height: 24px;
          }
        }

        .pagination {
          width: 100%;
          padding: 20px 0;
          display: flex;
          justify-content: center;
          align-items: center;
          .prev,
          .next {
            width: 430px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 12px;
          }
          .btn {
            display: flex;
            .el-button {
              width: 40px;
              margin: 0 6px;
              background-color: #fff;
              border: 1px solid #dcdfe6;
              color: #000000;
              cursor: none;

              &.el-button--small {
                width: 24px !important;
                border-radius: 5px !important;
              }
            }
          }
        }
      }
    }
  }
}
</style>
